let texttohole = function ($) {

    /**
     * On select
     */
    let onSelect = function () {

        $('body').on('click', '.texttohole .custom-select .select-items', function (e) {
            e.preventDefault();
            let $select = $body.find('.texttohole .custom-select .select-selected');
            let $answersUser = $body.find('.texttohole .answers-user');
            let tabAnswersUser = [];
            $select.each(function (k) {
                tabAnswersUser.push($(this).text());
                if (k === $select.length - 1 && tabAnswersUser.length > 0) {
                    $answersUser.val(JSON.stringify(tabAnswersUser));

                    let $btnAnswers = $body.find('#module .texttohole .answers a');
                    let $next = $body.find('#module > section.main > .content > .next');

                    if ($select.length === tabAnswersUser.length) {
                        $btnAnswers.removeClass('disabled');
                        $next.removeClass('disabled');
                    } else {
                        $btnAnswers.addClass('disabled');
                        $next.addClass('disabled');
                    }
                }
            });
        });
    };

    /**
     * Run answers
     */
    let runAnswers = function () {
        $('body').on('click', '.texttohole .answers a', function (e) {
            e.preventDefault();
            let _this = this;
            if ($(_this).hasClass('disabled')) return false;
            $(_this).fadeOut(function () {
                $(_this).next().fadeIn();
            });
        });
    };

    return {
        init: function () {
            runAnswers();
            onSelect();
        }
    };
}(jQuery);

$(document).ready(function () {
    texttohole.init();
});